import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "./StateContainer";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import axios from "axios";
import NavbarModifica from "./NavbarModifica";
import { useParams } from "react-router-dom";
import SqlAgentChat from "../SqlAgentChat"
import ComportamentoAssistente from "./ComportamentoAssistente";
import DocumentiCarica from "./SezioniCaricaFile/DocumentiCarica";
import SitoWebUpload from "./SezioniCaricaFile/SitoWebUpload";
import { Link } from "react-router-dom";
import TestoLiberoCarica from "./SezioniCaricaFile/TestoLiberoCarica";
import NavbarGeneralEdit from "./NavbarGeneralEdit";
import QuestionsAndAnswers from "./SezioniCaricaFile/QuestionsAndAnswers";
import ZipPlugin from "../assets/plugins/wp-tailorgpt.zip";
import { MdFolder as Cartella } from "react-icons/md";
import { BsFillCheckCircleFill as Corretto } from "react-icons/bs";
import { BiErrorAlt as Errore } from "react-icons/bi";
import ModificaProgettoMobile from "./ProgettiMobile/ModificaProgettoMobile";
import AspettoChat from "./AspettoChat";
import Database from "./SezioniCaricaFile/Database";
import ProvaChatbotProgetto from "../ProvaChatbotProgetto";
import StoricoConversazioniPageMenu from "../StoricoConversazioniPageMenu";

const ModificaProgetto = () => {
  const { id } = useParams();


  const {
    setLoading,
    setError,
    token,
    titoloProgetto,
    aggiornamentoDatiProgetto,
    setAggiornamentoDatiProgetto,
    setPageTitle,
    windowWidth,
    cambiaSezioneNavbar,
    setCambiaSezioneNavbar,
    setTitoloProgetto,
    width,

    // Database
    endpointDatabase,
    setEndpointDatabase,
    nomeDatabase,
    setNomeDatabase,
    usernameDatabase,
    setUsernameDatabase,
    passwordDatabase,
    setPasswordDatabase,
    istruzioniDatabase,
    setIstruzioniDatabase,
  } = useContext(StateContext);

  let iframeCode = `${process.env.REACT_APP_CHATBOT}?projectCode=${id}`;

  const [dettagliProgetto, setDettagliProgetto] = useState([]);

  const [navbarGeneralChange, setNavbarGeneralChange] = useState(() => {
    return localStorage.getItem("navbarGeneralChange") || "#configuraProgetto";
  });

  const [copiatoCodiceIframe, setCopiatoCodiceIframe] = useState(false);
  const [copiatoCodiceScript, setCopiatoCodiceScript] = useState(false);
  const [twilioApiKey, setTwilioApiKey] = useState("");
  const [twilioSid, setTwilioSid] = useState("");
  const [whatsappIntegrationDetails, setWhatsappIntegrationDetails] = useState([])
  const [emailAssistantDetail, setEmailAssistantDetail] = useState([])
  const [telegramIntegrationDetails, setTelegramIntegrationDetails] = useState([])


  // "whatsapp": {
  //           "fbAppId": null,
  //           "fbAppSecret": null,
  //           "fbPhoneNumberId": null,
  //           "fbAccessToken": null
  //       },
  const AggiornaProgetto = (event) => {
    event.preventDefault();
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: id,
        twilioAuthToken: twilioApiKey,
        twilioAccountSid: twilioSid,
        fbAppId: whatsappIntegrationDetails.fbAppId,
        fbAppSecret: whatsappIntegrationDetails.fbAppSecret,
        fbPhoneNumberId: whatsappIntegrationDetails.fbPhoneNumberId,
        fbAccessToken: whatsappIntegrationDetails.fbAccessToken,
        email: emailAssistantDetail.email,
        imapServer: emailAssistantDetail.imapServer,
        imapPassword: emailAssistantDetail.imapPassword,
        imapPort: emailAssistantDetail.imapPort,
        smtpServer: emailAssistantDetail.smtpServer,
        smtpPort: emailAssistantDetail.smtpPort,
        telegramToken: telegramIntegrationDetails.token
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else if (response.status === 200) {
          setAggiornamentoDatiProgetto(response.status);
          console.log(response);
        }
      })
      .catch((err) => setError(err));
  };

  
  useEffect(() => {
    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: id,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setLoading(false);
          setError("");
          if (response.data.response) {
            console.log(response.data.response);
            setDettagliProgetto([response.data.response]);
            setTitoloProgetto(response.data.response.title);
            setTwilioApiKey(response.data.response.twilio.authToken)
            setTwilioSid(response.data.response.twilio.accountSid)
            setWhatsappIntegrationDetails(response.data.response.whatsapp)
            setEmailAssistantDetail(response.data.response.email)
            setTelegramIntegrationDetails(response.data.response.telegram)

            if (response.data.response && response.data.response.mysql) {
              let mysql = response.data.response.mysql;
              console.log(mysql);
              setEndpointDatabase(mysql.endpoint || "");
              setNomeDatabase(mysql.database || "");
              setUsernameDatabase(mysql.user || "");
              setPasswordDatabase(mysql.password || "");
              setIstruzioniDatabase(mysql.promptInstruction || "");
            }
          } else {
            window.location.replace("/dashboard");
          }
        }
      })
      .catch((err) => setError(err));
  }, [aggiornamentoDatiProgetto]);

  const handleCopyClick = () => {
    let iframeCode = document.getElementById("iframeCode").innerText;
    setCopiatoCodiceIframe(false);
    setCopiatoCodiceScript(false);

    navigator.clipboard
      .writeText(iframeCode)
      .then(function () {
        setCopiatoCodiceIframe(true);
      })
      .catch(function (error) {
        console.error("Errore durante la copia del codice:", error);
      });
  };

  const handleCopyClickScript = () => {
    setCopiatoCodiceIframe(false);
    setCopiatoCodiceScript(false);
    let scriptCode = document.getElementById("scriptCode").innerText;

    navigator.clipboard
      .writeText(scriptCode)
      .then(function () {
        setCopiatoCodiceScript(true);
      })
      .catch(function (error) {
        console.error("Errore durante la copia del codice:", error);
      });
  };

  const RemoveUpdateDataModal = () => {
    setAggiornamentoDatiProgetto("");
  };

  const RemoveErrorUpdateDataModal = () => {
    setAggiornamentoDatiProgetto("");
  };




  let type = dettagliProgetto[0] && dettagliProgetto[0].type
  return (
    <>
      <Navbar></Navbar>
      <Sidebar></Sidebar>

      <div className='page-content'>
        <div className='main-content'>
          {windowWidth > 992 ? (
            <div className='container-fluid '>
              <div className='row'>
                <div className='col-12'>
                  <div className='page-title-box d-flex align-items-center justify-content-between p-0'>
                    <h4 className='mb-0'>Personalizza Assistente</h4>

                    
                  </div>
                  <div className='page-title-right pb-3'>
                    <ol className='breadcrumb m-0'>
                      <li className='breadcrumb-item'>
                        <Link
                          to='/dashboard'
                          className='text-decoration-none text-secondary'
                        >
                          Home
                        </Link>
                      </li>
                      <li className='breadcrumb-item text-secondary'>
                        Personalizza / {titoloProgetto}
                      </li>
                    </ol>
                  </div>
                </div>
                <div className='w-100 user-chat mt-4 mt-sm-0 ms-lg-0 my-3 '>
                  {aggiornamentoDatiProgetto ? (
                    aggiornamentoDatiProgetto === 200 &&
                    aggiornamentoDatiProgetto !== "" ? (
                      <div
                        className='overlay d-flex align-items-center '
                        id='overlayDataModal'
                      >
                        <div className='modal' style={{ display: "block" }}>
                          <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='modal-title d-flex align-items-center gap-2'>
                                  <Corretto className='text-success fs-4' />{" "}
                                  Dati aggiornati
                                </h5>
                              </div>
                              <div className='modal-body'>
                                <p>
                                  I dati sono stati correttamente aggiornati.
                                </p>
                              </div>
                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-secondary'
                                  onClick={RemoveUpdateDataModal}
                                >
                                  Chiudi
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : aggiornamentoDatiProgetto !== 200 &&
                      aggiornamentoDatiProgetto !== "" ? (
                      <div
                        className='overlay d-flex align-items-center'
                        id='overlayErrorModal'
                      >
                        <div className='modal' style={{ display: "block" }}>
                          <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='modal-title d-flex align-items-center gap-2'>
                                  <Errore className='text-danger fs-4' /> Errore
                                </h5>
                              </div>
                              <div className='modal-body'>
                                <p>
                                  Mi dispiace, c'è stato un problema con
                                  l'aggiornamento dei dati. Riprova più tardi.
                                </p>
                              </div>
                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-secondary'
                                  onClick={RemoveErrorUpdateDataModal}
                                >
                                  Chiudi
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <div className='card mb-3'>
                    <div className='card-header d-grid align-items-center bg-white p-0'>
                      <NavbarGeneralEdit
                        navbarGeneralChange={navbarGeneralChange}
                        setNavbarGeneralChange={setNavbarGeneralChange}
                        dettagliProgetto={dettagliProgetto}
                      ></NavbarGeneralEdit>
                    </div>
                  </div>

                  <div className='col-12 mb-3'>
                    <div className='d-md-none'>
                      <Link
                        to={`/progetti/modifica-progetto/${id}/test-assistente`}
                        className='btn bg-orange text-white fw-bold font-size-15 w-100 py-2'
                      >
                        Prova l'Assistente
                      </Link>
                    </div>
                  </div>

                  {navbarGeneralChange === "#configuraProgetto" ? (
                    <ComportamentoAssistente progetto={dettagliProgetto} />
                  ) : navbarGeneralChange === "#aspettoChat" ? (
                    <AspettoChat progetto={dettagliProgetto} />
                  ) : navbarGeneralChange === "#agenteSQL" ? (
                    <SqlAgentChat progetto={dettagliProgetto} />
                  ): navbarGeneralChange === "#conversationalAssistant" && type !== "mysql-agent" ? (
                    <ProvaChatbotProgetto progetto={dettagliProgetto} />
                  ): navbarGeneralChange === "#conversationalAssistant" && type === "mysql-agent" ? (
                    <SqlAgentChat progetto={dettagliProgetto} />
                  ): navbarGeneralChange === "#storicoConversazioni" ? (
                    <StoricoConversazioniPageMenu progetto={dettagliProgetto} setNavbarGeneralChange={setNavbarGeneralChange} setCambiaSezioneNavbar={setCambiaSezioneNavbar}/>
                  ):  navbarGeneralChange === "#includiInProgetto" ? (
                    <div className='row'>
                      <div className='col-12'>
                        {copiatoCodiceIframe ? (
                          <div
                            className='alert alert-success mt-3'
                            role='alert'
                          >
                            Codice <code>&lt;iframe/&gt;</code> copiato negli
                            appunti
                          </div>
                        ) : (
                          ""
                        )}
                        <h3 className="mt-2">Includi nel sito</h3>
                        <div className='card p-2 mt-4'>
                          <div className='card-header bg-white d-flex align-items-center justify-content-between'>
                            <h6 className='m-0 fw-bold'>
                              Copia il codice dell'iframe
                            </h6>
                            <button
                              id='copyButton'
                              className='btn btn-dark'
                              onClick={handleCopyClick}
                            >
                              Copia
                            </button>
                          </div>
                          <div className='card-body'>
                            <code
                              id='iframeCode'
                              onClick={handleCopyClick}
                              style={{ cursor: "pointer" }}
                            >
                              &lt;iframe src="{iframeCode}" width="100%"
                              height="400px"&gt;&lt;/iframe&gt;
                            </code>
                          </div>
                        </div>
                      </div>
                      {/* Script */}
                      <div className='col-12'>
                        {copiatoCodiceScript ? (
                          <div
                            className='alert alert-success mt-3'
                            role='alert'
                          >
                            Codice <code>&lt;script/&gt;</code> copiato negli
                            appunti
                          </div>
                        ) : (
                          ""
                        )}
                        <div className='card p-2 mt-4'>
                          <div className='card-header bg-white d-flex align-items-center justify-content-between'>
                            <h6 className='m-0 fw-bold'>
                              Copia il codice dello script
                            </h6>
                            <button
                              id='copyButtonScript'
                              className='btn btn-dark'
                              onClick={handleCopyClickScript}
                            >
                              Copia
                            </button>
                          </div>
                          <div className='card-body'>
                            <code
                              id='scriptCode'
                              onClick={handleCopyClickScript}
                              style={{ cursor: "pointer" }}
                            >
                              &lt;script
                              src="https://app.tailorgpt.ai/js/ScriptChatbot.js"
                              async projectCode="{`${id}`}
                              "&gt;&lt;/script&gt;
                            </code>
                          </div>
                        </div>

                        <div className='card p-2 mt-4'>
                          <div className='card-header bg-white d-flex align-items-center justify-content-between'>
                            <h6 className='m-0 fw-bold'>
                              Scarica il plugin per il tuo sito Wordpress
                            </h6>
                            <a
                              id='copyButtonScript'
                              className='btn btn-dark'
                              href={ZipPlugin}
                            >
                              Download
                            </a>
                          </div>
                          <div className='card-body'>
                            <a
                              href={ZipPlugin}
                              className='text-dark text-decoration-none d-flex align-items-center gap-2'
                            >
                              <Cartella /> Plugin Wordpress Tailor GPT
                            </a>
                          </div>
                        </div>

                        {/* Assistente Whatsapp  */}
                        <h3 className="mt-5">Includi assistente Whatsapp</h3>
                        <div className='card p-2 mt-4'>
                          <div className='card-body bg-white'>
                          <div>
                            <p className="mt-2"><label className="form-label">URL di callback:</label> {process.env.REACT_APP_WEBHOOK_CALLBACK}</p>
                            <p className="mt-2 mb-2"><label className="form-label">Token di verifica:</label> {process.env.REACT_APP_VERIFY_TOKEN}</p>
                          </div>
                          <div className='form-outline'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                                Id app
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci il titolo del progetto'
                                value={whatsappIntegrationDetails.fbAppId}
                                onChange={(event) => setWhatsappIntegrationDetails({...whatsappIntegrationDetails, fbAppId: event.target.value})}
                              />
                          </div>

                          <div className='form-outline mt-2'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                              Id segreto app
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci il titolo del progetto'
                                value={whatsappIntegrationDetails.fbAppSecret}
                                onChange={(event) => setWhatsappIntegrationDetails({...whatsappIntegrationDetails, fbAppSecret: event.target.value})}
                              />
                            </div>

                            <div className='form-outline mt-2'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                                Id numero telefonico
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci il titolo del progetto'
                                value={whatsappIntegrationDetails.fbPhoneNumberId}
                                onChange={(event) => setWhatsappIntegrationDetails({...whatsappIntegrationDetails, fbPhoneNumberId: event.target.value})}
                              />
                            </div>

                            <div className='form-outline mt-2'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                               Token d'accesso
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci il titolo del progetto'
                                value={whatsappIntegrationDetails.fbAccessToken}
                                onChange={(event) => setWhatsappIntegrationDetails({...whatsappIntegrationDetails, fbAccessToken: event.target.value})}
                              />
                            </div>

                            <button className='btn bg-green-second text-white mt-3' onClick={(AggiornaProgetto)}>Aggiorna</button>
                            

                          </div>


                        </div>


                        {/* Assistente Telegram  */}
                        <h3 className="mt-5">Includi assistente Telegram</h3>
                        <div className='card p-2 mt-4'>
                          <div className='card-body bg-white'>
                          <div className='form-outline'>
                              <label className='form-label' htmlFor='tokenTelegram'>
                                Token
                              </label>
                              <input
                                type='text'
                                id='tokenTelegram'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci il titolo del progetto'
                                value={telegramIntegrationDetails.token}
                                onChange={(event) => setTelegramIntegrationDetails({...telegramIntegrationDetails, token: event.target.value})}
                              />
                          </div>
                            <button className='btn bg-green-second text-white mt-3' onClick={(AggiornaProgetto)}>Aggiorna</button>
                          </div>
                        </div>

                        {/* Assistente Email  */}
                        <h3 className="mt-5">Includi assistente Email</h3>
                        <div className='card p-2 mt-4'>
                          <div className='card-body bg-white'>
                          <div className='form-outline'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                                Email
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci email'
                                value={emailAssistantDetail.email}
                                onChange={(event) => setEmailAssistantDetail({...emailAssistantDetail, email: event.target.value})}
                              />
                          </div>

                          <div className='form-outline mt-2'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                              Password
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci password'
                                value={emailAssistantDetail.imapPassword}
                                onChange={(event) => setEmailAssistantDetail({...emailAssistantDetail, imapPassword: event.target.value})}
                              />
                            </div>

                            <div className='form-outline mt-2'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                                Server IMAP
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci server IMAP'
                                value={emailAssistantDetail.imapServer}
                                onChange={(event) => setEmailAssistantDetail({...emailAssistantDetail, imapServer: event.target.value})}
                              />
                            </div>

                            <div className='form-outline mt-2'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                               Porta IMAP
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci porta IMAP'
                                value={emailAssistantDetail.imapPort}
                                onChange={(event) => setEmailAssistantDetail({...emailAssistantDetail, imapPort: event.target.value})}
                              />
                            </div>



                            <div className='form-outline mt-2'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                                Server SMTP
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci server SMTP'
                                value={emailAssistantDetail.smtpServer}
                                onChange={(event) => setEmailAssistantDetail({...emailAssistantDetail, smtpServer: event.target.value})}
                              />
                            </div>

                            <div className='form-outline mt-2'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                               Porta SMTP
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci porta SMTP'
                                value={emailAssistantDetail.smtpPort}
                                onChange={(event) => setEmailAssistantDetail({...emailAssistantDetail, smtpPort: event.target.value})}
                              />
                            </div>

                            <button className='btn bg-green-second text-white mt-3' onClick={(AggiornaProgetto)}>Aggiorna</button>
                            

                          </div>


                        </div>


                        {/* Assistente Twilio  */}

                        <h3 className="mt-5">Includi assistente telefonico con Twilio</h3>
                        <div className='card p-2 mt-4'>
                          <div className='card-body bg-white'>
                          <div className='form-outline'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                                Twilio Api Key
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci il titolo del progetto'
                                value={twilioApiKey}
                                onChange={(event) => setTwilioApiKey(event.target.value)}
                              />
                          </div>

                          <div className='form-outline mt-2'>
                              <label className='form-label' htmlFor='formControlLgcvv'>
                                Twilio SID
                              </label>
                              <input
                                type='text'
                                id='formControlLgcvv'
                                className='form-control form-control-lg fs-6'
                                placeholder='Inserisci il titolo del progetto'
                                value={twilioSid}
                                onChange={(event) => setTwilioSid(event.target.value)}
                              />
                            </div>

                            <button className='btn bg-green-second text-white mt-3' onClick={(AggiornaProgetto)}>Aggiorna</button>
                            

                          </div>


                        </div>
                        
                      </div>
                      
                    </div>

                    
                  ) : (
                    <div className='card' style={{ width: "auto" }}>
                      <div className='card-header p-0 bg-white'>
                        <NavbarModifica
                          setCambiaSezioneNavbar={setCambiaSezioneNavbar}
                          cambiaSezioneNavbar={cambiaSezioneNavbar}
                          dettagliProgetto={dettagliProgetto}
                        ></NavbarModifica>
                      </div>
                      <div
                        className='card-body py-2'
                        style={{
                          overflow: windowWidth < 600 ? "scroll" : "initial",
                          width: "auto",
                          whiteSpace: windowWidth < 600 ? "nowrap" : "normal",
                        }}
                      >
                        <div className='container-fluid position-relative p-0 py-4'>
                          <div className='row position-relative'>
                            {cambiaSezioneNavbar === "#documenti" && type !== "mysql-agent" ? (
                              <DocumentiCarica />
                            ) : cambiaSezioneNavbar === "#sitoWeb" && type !== "mysql-agent" ? (
                              <SitoWebUpload />
                            ) : cambiaSezioneNavbar ===
                              "#questionAndAnswers" && type !== "mysql-agent" ? (
                              <QuestionsAndAnswers
                                dettagliProgetto={dettagliProgetto}
                              />
                            ) : cambiaSezioneNavbar === "#database" || type === "mysql-agent" ? (
                              <Database dettagliProgetto={dettagliProgetto} />
                            ) : (
                              <TestoLiberoCarica
                                dettagliProgetto={dettagliProgetto}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Caricamento dei files */}
              <div className='row'>
                <div className='w-100 user-chat mt-4 mt-sm-0 ms-lg-0 my-3'></div>
              </div>
            </div>
          ) : (
            <>
              {aggiornamentoDatiProgetto ? (
                aggiornamentoDatiProgetto === 200 &&
                aggiornamentoDatiProgetto !== "" ? (
                  <div
                    className='overlay d-flex align-items-center '
                    id='overlayDataModal'
                  >
                    <div className='modal' style={{ display: "block" }}>
                      <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                          <div className='modal-header'>
                            <h5 className='modal-title d-flex align-items-center gap-2'>
                              <Corretto className='text-success fs-4' /> Dati
                              aggiornati
                            </h5>
                          </div>
                          <div className='modal-body'>
                            <p>I dati sono stati correttamente aggiornati.</p>
                          </div>
                          <div className='modal-footer'>
                            <button
                              type='button'
                              className='btn btn-secondary'
                              onClick={RemoveUpdateDataModal}
                            >
                              Chiudi
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : aggiornamentoDatiProgetto !== 200 &&
                  aggiornamentoDatiProgetto !== "" ? (
                  <div
                    className='overlay d-flex align-items-center'
                    id='overlayErrorModal'
                  >
                    <div className='modal' style={{ display: "block" }}>
                      <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                          <div className='modal-header'>
                            <h5 className='modal-title d-flex align-items-center gap-2'>
                              <Errore className='text-danger fs-4' /> Errore
                            </h5>
                          </div>
                          <div className='modal-body'>
                            <p>
                              Mi dispiace, c'è stato un problema con
                              l'aggiornamento dei dati. Riprova più tardi.
                            </p>
                          </div>
                          <div className='modal-footer'>
                            <button
                              type='button'
                              className='btn btn-secondary'
                              onClick={RemoveErrorUpdateDataModal}
                            >
                              Chiudi
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <ModificaProgettoMobile progetto={dettagliProgetto} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ModificaProgetto;
